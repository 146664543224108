const config = {
  LPStake: "0xc2C144e33821dCc7aE25A236eba08f3A67C36E43",
  routerPath: "0x05BD5Fc094cff63fBfEb4Cdde8ba747560b76950",
  USDQAddress: "0x9E50ad3bD5C2402aF4eFceEA5e5db95e0CC3164b",
  PPTAddress: "0xA5f68ec39a2C2281F8bDE74303731Bf873cecA1c",
  chainId: 409409,
  exchangeAddress: "0xD86e2eC3699E80530b0DCA4c7E31984fA0B8fE58",
  backicoAddress: "0x9Beca7A3f243BB1B4257bEE43769DF73F57CF26C", // 私募
  transferAddress: "0x611F5eE6F0FbE624b8A3a8870084bE259BD19cc9", // 跨链合约
  yiquefenhongAddress: "0x17290D96Dec4affe66298A04F85d923d81FB2889", // 锁仓提币1合约地址（易趣分红）
  jijinhuiAddress: "0xfb8daf9d90760f070C0Df64A0AA4BA6b0F8385a0", // 锁仓提币2合约地址（基金会）

  // // 本地
  // api: 'https://backexplorer-p.dmds.xyz', // 浏览器接口
  // jApi: '', // 结算接口

  // // 内网测试站
  // api: 'https://backexplorer-p.dmds.xyz', // 浏览器接口
  // jApi: 'https://xs2783.goer.cloud', // 结算接口

  // 外网测试站
  api: "https://explore.back.cc", // 浏览器接口
  jApi: "https://123.back.cc" // 结算接口
};

const backChainContract = [
  {
    id: 3,
    chainId: 409409,
    cName: "back",
    transferAddress: "0x611F5eE6F0FbE624b8A3a8870084bE259BD19cc9", // 跨链合约
    tokens: [
      {
        name: "bnb",
        cName: "bsc",
        isToken: true,
        address: "0x2e759A326CAAF01b0F3023EFe0a2B1549686CE6d" // back链bnb
      },
      {
        name: "eth",
        cName: "eth",
        isToken: true,
        address: "0x5352e948dd1e6F9581f6a3740737ea0eE7EBaE1a" // back链eth
      },
      {
        name: "trx",
        cName: "trx",
        isToken: true,
        address: "0xd0eE7aE00ca616149f78A5d1EA01f179280878FB" // back链trx
      },
      {
        name: "usdq",
        cName: "back",
        isToken: true,
        address: "0x9E50ad3bD5C2402aF4eFceEA5e5db95e0CC3164b" // back链USDT
      }
    ]
  }
];

const otherChainContract = [
  {
    id: 1,
    chainId: 97,
    cName: "bsc",
    transferAddress: "0xB9E398eDCBbF2a64770fD8Ba613a6aAA2C6fdb7E", // 跨链合约
    tokens: [
      {
        name: "bnb",
        cName: "bsc",
        isToken: false,
        address: "0x0000000000000000000000000000000000000000"
      },
      {
        name: "usdt",
        cName: "bsc",
        isToken: true,
        address: "0xD6055D2543BB3A5e60ca7b40c7c369B55e337098" // busd地址
      }
    ]
  },
  {
    id: 0,
    chainId: 3,
    cName: "eth",
    transferAddress: "0x133D626a32B24DC972536659AD9AeB22F7AEaA4a", // 跨链合约
    tokens: [
      {
        name: "eth",
        cName: "eth",
        isToken: false,
        address: "0x0000000000000000000000000000000000000000"
      },
      {
        name: "usdt",
        cName: "trx",
        isToken: true,
        address: "0xcbDe68abEaF79CD4F17A70b2DbaC2402eDe1364D" // usdt地址
      }
    ]
  },
  {
    id: 2,
    chainId: "trx",
    cName: "trx",
    transferAddress: "TRgMBkNbGYeb7XERNJudXgw2NDxJcVGZAF", // 跨链合约
    tokens: [
      {
        name: "trx",
        cName: "trx",
        isToken: false,
        address: "0x0000000000000000000000000000000000000000"
      },
      {
        name: "usdt",
        cName: "trx",
        isToken: true,
        address: "TK1mUd3cXRTGMsXGC5v1kZRmfb8mgPxidR" // usdt地址
      }
    ]
  }
];

export { config, backChainContract, otherChainContract };
