import Web3 from "web3";
import store from "../store/index";
import { ElNotification } from "element-plus";
import { config } from "@/utils/config";
import { i18n } from "../utils/i18n";
const web3Data = {
  web3: {},
  t: i18n.global.t,
  web3Provider: null,
  initWeb3: async chainId => {
    if (window.ethereum) {
      web3Data.web3Provider = window.ethereum;
      window.ethereum.on("accountsChanged", accounts => {
        let acc = web3Data.web3.utils.toChecksumAddress(accounts[0]);
        store.commit("updateAccount", [acc]);
      });
      try {
        await window.ethereum.enable();
      } catch (error) {
        Promise.reject(error);
      }
    } else {
      if (window.web3) {
        web3Data.web3Provider = window.web3.currentProvider;
      } else {
        ElNotification.error({
          message: web3Data.t("請連接MetaMask插件，或在TP錢包中打開")
        });
      }
    }
    if (web3Data.web3Provider) {
      web3Data.web3 = new Web3(web3Data.web3Provider);
      web3Data.changeNetwork(chainId);
    }
  },
  changeNetwork: async function(chainId = config.chainId) {
    let id = await web3Data.web3.eth.net.getId();
    if (id != chainId) {
      let tip = "";
      if (chainId == 97) {
        tip = web3Data.t("請將網絡切換到幣安網絡");
      }
      if (chainId == 3) {
        tip = web3Data.t("請將網絡切換到以太網絡");
      }
      if (chainId == 409409) {
        tip = web3Data.t("請將網絡切換到BACK網絡");
      }
      ElNotification.error({
        title: web3Data.t("網路錯誤"),
        message: tip
      });
    } else {
      store.commit("updateNetId", chainId);
      store.commit("updateProvider", web3Data.web3);
      web3Data.web3.eth.getAccounts().then(accounts => {
        let acc = web3Data.web3.utils.toChecksumAddress(accounts[0]);
        store.commit("updateAccount", [acc]);
      });
      web3Data.web3Provider.on("accountsChanged", accounts => {
        let acc = web3Data.web3.utils.toChecksumAddress(accounts[0]);
        store.commit("updateAccount", [acc]);
      });
      web3Data.web3Provider.on("chainChanged", id => {
        if (id != `0x${chainId.toString(16)}`) {
          store.commit("updateAccount", []);
        } else {
          store.commit("updateProvider", web3Data.web3);
          web3Data.web3.eth.getAccounts().then(accounts => {
            let acc = web3Data.web3.utils.toChecksumAddress(accounts[0]);
            store.commit("updateAccount", [acc]);
          });
        }
      });
    }
  },
  initTronWeb: async () => {
    if (window.tronWeb) {
      try {
        await window.tronWeb.request({ method: "tron_requestAccounts" });
        if (window.tronWeb.defaultAddress) {
          store.commit(
            "updateTronAccount",
            window.tronWeb.defaultAddress.base58
              ? window.tronWeb.defaultAddress.base58
              : ""
          );
          store.commit("updateTronProvider", window.tronWeb);
          store.commit("updateNetId", "trx");
        }
      } catch (error) {
        let i = 0;
        web3Data.Inval = setInterval(() => {
          i = i + 1;
          web3Data.defaultAddress = window.tronWeb.defaultAddress.base58
            ? window.tronWeb.defaultAddress.base58
            : "";
          if ((!window.tronWeb || !web3Data.defaultAddress) && i == 3) {
            ElNotification.error({
              title: web3Data.t("提示"),
              message: web3Data.t("請連接TronLink插件，或在TP錢包中打開")
            });
          }
          if (web3Data.defaultAddress) {
            window.clearInterval(web3Data.Inval);
            store.commit(
              "updateTronAccount",
              window.tronWeb.defaultAddress.base58
                ? window.tronWeb.defaultAddress.base58
                : ""
            );
            store.commit("updateTronProvider", window.tronWeb);
            store.commit("updateNetId", "trx");
          }
        }, 1000);
      }
    } else {
      ElNotification.error({
        title: web3Data.t("提示"),
        message: web3Data.t("請連接TronLink插件，或在TP錢包中打開")
      });
    }
  }
};

export default web3Data;
