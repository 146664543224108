<template>
  <el-container>
    <el-header>
      <Header v-if="!$isInMobile" />
      <Headers v-else />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
    <Footer />
    <mobile-footer></mobile-footer>
  </el-container>
</template>
<script>
import { reactive, toRefs, computed, watch } from "vue";
import { useStore } from "vuex";
import Header from "@/components/Header";
import Headers from "@/components/Headers";
import Footer from "@/components/Footer";
import MobileFooter from "@/components/MobileFooter";
import web3Data from "@/utils/initWeb3";
export default {
  components: {
    Header,
    Footer,
    MobileFooter,
    Headers
  },
  //
  setup() {
    const store = useStore();
    const data = reactive({
      themeMode: computed(() => store.state.themeMode)
    });
    document.getElementsByTagName(
      "body"
    )[0].className = `theme-${data.themeMode}`;
    watch(
      () => data.themeMode,
      val => {
        if (val) {
          document.getElementsByTagName(
            "body"
          )[0].className = `theme-${data.themeMode}`;
        }
      }
    );
    if (!location.href.includes('cross/chain')) {
      web3Data.initWeb3()
    }
    const refData = toRefs(data);
    return {
      ...refData
    };
    //  let web3 = new Web3(web3Provider);
    //  let factoryContract = new web3.eth.Contract(factoryAbi, '0xc807D8D923945eF20731c9b4861847e89A32DA02')
    //  factoryContract.methods.getReserves('0x045F8EdA5aDD0bd03442d85a563764518CcFB2bd', '0xe579156f9dEcc4134B5E3A30a24Ac46BB8B01281').call().then((result) => {
    //    console.log(result)
    //  })
  }
};
</script>
<style lang="scss">
@import "./assets/style/common.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #111;
  height: 100%;
  .el-container {
    min-height: 100%;
    padding-top: 60px;
  }
  .el-containers {
    padding: 0 !important;
  }
  .el-header {
    padding: 0;
    height: 60px !important;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 500;
  }
  a {
    color: #0956d0;
  }
  .el-main {
    padding: 0;
    overflow: unset;
  }
}

.el-headers {
  display: none;
  height: 0 !important;
}
</style>
