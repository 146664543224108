<template>
  <div v-if="$isInMobile ? '' : res" class="mobile-footer-kong"></div>
  <div v-if="$isInMobile ? '' : res" class="mobile-footer">
    <div class="wrap flex-between">
      <div class="account-btn flex-middle" v-if="address">
        <p class="text text-ellipsis flex-item">
          {{ address.substring(0, 6) }}...{{
            address.substring(address.length - 4, address.length)
          }}
        </p>
        <div class="point">
          <svg
            height="100"
            version="1.1"
            width="100"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="overflow: hidden; position: relative;"
          >
            <desc style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
              Created with Raphaël 2.3.0
            </desc>
            <defs style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></defs>
            <rect
              x="0"
              y="0"
              width="16"
              height="16"
              rx="0"
              ry="0"
              fill="#f5e900"
              stroke="none"
              style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"
            ></rect>
            <rect
              x="0"
              y="0"
              width="16"
              height="16"
              rx="0"
              ry="0"
              fill="#c81432"
              stroke="none"
              transform="matrix(-0.9993,0.0367,-0.0367,-0.9993,15.6207,16.3788)"
              style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"
            ></rect>
            <rect
              x="0"
              y="0"
              width="16"
              height="16"
              rx="0"
              ry="0"
              fill="#2349e1"
              stroke="none"
              transform="matrix(0.8983,-0.4394,0.4394,0.8983,4.4322,0.6987)"
              style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"
            ></rect>
            <rect
              x="0"
              y="0"
              width="16"
              height="16"
              rx="0"
              ry="0"
              fill="#fa9a00"
              stroke="none"
              transform="matrix(0.3343,-0.9425,0.9425,0.3343,-15.1471,13.0953)"
              style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"
            ></rect>
          </svg>
        </div>
      </div>
      <div v-else></div>
      <div class="more-btn flex-center" @click.stop="showLinkFun">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          class="sc-TOsTZ cgjuDl"
        >
          <path
            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
        <div class="more-links" v-show="showLink" id="linkDom">
          <a
            target="_blank"
            v-if="local === 'en'"
            rel="noopener noreferrer"
            href="https://preview-static.clewm.net/cli/view-doc/view.html?url=https%3A%2F%2Fncstatic.clewm.net%2Frsrc%2F2021%2F0809%2F13%2F690478c3e77acc04218f933d080ca5f3.pdf&filename=KIS%20Smart%20Contract%20Audit%20Report.pdf&download=1&code=qFZHeDG&open=a1a4f764619343c79419586366b9cc39&action=&_=1628488610718&frompc=false&org_id=15715899&web_url=http%3A%2F%2Fqr61.cn%2FovMfl9%2FqFZHeDG&app=&shareUrl=&shareName=KIS%2520Smart%2520Contract%2520Audit%2520Repor&shareCodeUrl=&auth_app_maps=&auth_maps=&auth_maps_publickey=&auth_maps_time=&surl=&free=1&uni=&collect=0&from=h5&show_collect=1&show_share=1"
            class="link-item"
            >{{ $t("合約審計") }}</a
          >
          <a
            target="_blank"
            v-if="local === 'zh-cn'"
            rel="noopener noreferrer"
            href="https://preview-static.clewm.net/cli/view-doc/view.html?url=https%3A%2F%2Fncstatic.clewm.net%2Frsrc%2F2021%2F0809%2F13%2Fe9691251ee09c86343a0aed303a5a190.pdf&filename=KIS%E6%99%BA%E8%83%BD%E5%90%88%E7%BA%A6%E5%AE%A1%E8%AE%A1%E6%8A%A5%E5%91%8A.pdf&download=1&code=qBqTmOG&open=a1a4f764619343c79419586366b9cc39&action=&_=1628488718428&frompc=false&org_id=15715899&web_url=http%3A%2F%2Fqr61.cn%2FovMfl9%2FqBqTmOG&app=&shareUrl=&shareName=KIS%25E6%2599%25BA%25E8%2583%25BD%25E5%2590%2588%25E7%25BA%25A6%25E5%25AE%25A1%25E8%25AE%25A1%25E6%258A%25A5%25E5%2591%258A.pdf&shareCodeUrl=&auth_app_maps=&auth_maps=&auth_maps_publickey=&auth_maps_time=&surl=&free=1&uni=&collect=0&from=h5&show_collect=1&show_share=1"
            class="link-item"
            >{{ $t("合約審計") }}</a
          >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://ok.kiswap.club/home/index?lang=zh-CN"
            class="link-item"
            >{{ $t("docs") }}</a
          >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/Kiswap_offical"
            class="link-item"
            >{{ $t("twitter") }}</a
          >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/Kiswap"
            class="link-item"
            >{{ $t("telegramGroup") }}</a
          >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/KiSwapAnnouncement"
            class="link-item"
            >{{ $t("telegram") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IndexTop from "@/views/index/IndexTop";
import { CountTo } from "vue3-count-to";
import { reactive, toRefs, computed, watch, onMounted } from "vue";
import poolAbi from "@/utils/poolAbi";
import { useStore } from "vuex";
import factoryAbi from "@/utils/FactoryAbi";
import poolList from "@/utils/poolList";
import tokenAbi from "@/utils/abi";
import { config } from "@/utils/config";
import { getLang } from "@/utils/i18n";
export default {
  components: {
    IndexTop,
    CountTo,
  },
  setup() {
    const store = useStore();
    const data = reactive({
      res: false,
      local: computed(() => store.state.locale),
      rate: computed(() => store.state.rate),
      address: computed(() => store.state.accounts[0]),
      showLink: false,
      showLinkFun: () => {
        data.showLink = !data.showLink;
      },
    });
    if (data.address) {
    }
    onMounted(() => {
      let settingDialogDom = document.getElementById("linkDom");
      document.addEventListener("click", (e) => {
        if (data.showLink) {
          if (!settingDialogDom.contains(e.target)) {
            data.showLink = false;
          }
        }
      });
    });
    watch(
      () => data.address,
      (val) => {
        if (val) {
        }
      }
    );
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
};
</script>
<style lang="scss">
.mobile-footer-kong {
  display: none;
  height: 72px;
}
.mobile-footer {
  position: fixed;
  bottom: 0;
  display: none;
  z-index: 99;
  left: 0;
  right: 0;
  .wrap {
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: #181d21;
    padding: 1rem;
    .account-btn {
      border-radius: 12px;
      background-color: #2c2f36;
      border: 1px solid #40444f;
      color: #08e8ce;
      font-weight: 500;
      padding: 0.5rem;
      height: 38px;
      &:hover {
        border: 1px solid #68ca78;
        background-color: #373b44;
      }
      .text {
        width: fit-content;
        font-weight: 500;
        white-space: nowrap;
        margin: 0 0.5rem 0 0.25rem;
        font-size: 1rem;
      }
      .point {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-radius: 50px;
        overflow: hidden;
        width: 16px;
        height: 16px;
        display: inline-block;
      }
    }
    .more-btn {
      margin-left: 0.5rem;
      height: 35px;
      background-color: #40444f;
      padding: 0.15rem 0.5rem;
      border-radius: 0.5rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      svg {
        path {
          stroke: #08e8ce;
        }
      }
      .more-links {
        top: -12.5rem;
        min-width: 8.125rem;
        background-color: #40444f;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01),
          0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
          0px 24px 32px rgba(0, 0, 0, 0.01);
        border-radius: 12px;
        padding: 0.5rem;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        font-size: 1rem;
        position: absolute;
        right: 0rem;
        z-index: 100;
        .link-item {
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
          padding: 0.5rem 0.5rem;
          color: #dfe0e2 !important;
          font-weight: 500;
          &:hover {
            color: #08e8ce;
          }
        }
      }
    }
  }
}

.theme-light {
  .mobile-footer {
    .wrap {
      background-color: #ffffff;
      .account-btn {
        background-color: #f7f8fa;
        color: #333333;
        border: 1px solid #edeef2;
        &:hover {
          border: 1px solid #68ca78;
          background-color: #fff;
        }
      }
      .more-btn {
        background-color: #edeef2;
        svg {
          path {
            stroke: #3a7343;
          }
        }
        .more-links {
          background-color: #edeef2;
          .link-item {
            color: #333333 !important;
            &:hover {
              color: #3a7343;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .mobile-footer-kong {
    display: block !important;
  }
  .mobile-footer {
    display: block !important;
  }
}
</style>
