import { createStore } from "vuex";
export default createStore({
  state: {
    accounts: [],
    provider: null,
    TronAccounts: '',
    TronProvider: null,
    netId: '',
    locale: '',
    themeMode: ''
  },
  mutations: {
    updateAccount(state, value) {
      state.accounts = value;
    },
    updateProvider(state, value) {
      state.provider = value;
    },
    updateTronAccount(state, value) {
      state.TronAccounts = value;
    },
    updateTronProvider(state, value) {
      state.TronProvider = value;
    },
    updateNetId(state, value) {
      state.netId = value;
    },
    updateLocale(state, value) {
      state.locale = value;
    },
    updateThemeMode(state, value) {
      state.themeMode = value;
    }
  },
  actions: {},
  modules: {}
});
