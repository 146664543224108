<template>
  <div class="footer-box">
    <div class="footer-wrap">{{ $t("版權所有Back") }} ©2022-2030</div>
  </div>
</template>
<style lang="scss" scoped>
.footer-box {
  overflow: hidden;
  min-width: 1200px;
}
.footer-wrap {
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: #232526;
  color: #ffffff;
  font-size: 14px;
  overflow: hidden;
}
@media screen and (max-width: 480px) {
  .footer-box {
    min-width: 100%;
  }
  .footer-wrap {
    font-size: 14px;
    height: auto;
    background-size: 150% auto;
    background-position: center bottom;
    .copy-text {
      margin: 0 auto;
      max-width: 65%;
    }
  }
}
.theme-light {
  .footer-wrap {
    background-image: url("~@/assets/image/light/botline.png");
    color: #666666;
  }
}
</style>
