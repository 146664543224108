<template>
  <div class="index-box">
    <div class="index-banner">
      <div class="banner-center">
        <div class="center-text-big">{{ $t("BACK去中心化交易所") }}</div>
        <div class="center-text-small">
          {{ $t("期待您的加入，Back歡迎您") }}
        </div>
        <a class="center-text-btn" href="/swap">{{ $t("立即加入") }}</a>
      </div>
    </div>
    <div class="content">
      <div class="index-title">
        <div class="title-en">MORE CHOICES</div>
        <div class="title-zh">{{ $t("BACK還有更多功能任您選擇") }}</div>
      </div>
      <div class="content-list">
        <div
          class="content-item"
          v-for="(item, index) in navList"
          :key="index"
          @click="$router.push({ name: item.url })"
        >
          <img :src="item.src" alt="" class="item-logo" />
          <div class="item-text-big text-ellipsis">{{ item.text_1 }}</div>
          <div class="item-text-small text-ellipsis">{{ item.text_2 }}</div>
          <a class="item-btn" :href="item.url">{{ item.btn_text }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import poolList from "@/utils/poolList";
import { config } from "@/utils/config";
import { useI18n } from "vue-i18n";
export default {
  components: {},
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const data = reactive({
      local: computed(() => store.state.locale),
      themeMode: computed(() => store.state.themeMode),
      poolPairList: poolList,
      rate: computed(() => store.state.rate),
      ratekis: computed(() => store.state.ratekis),
      web3: computed(() => store.state.provider),
      poolTotalLock: 0,
      totalAllocPoint: 0,
      totalMintDay: 0,
      totalMint: 0,
      kisBlance: 0,
      address: computed(() => store.state.accounts[0]),
      poolAddress: config.poolAddress,
      isLp: true,
      hgAmount: 0,
      navList: [
        {
          src: require("@/assets/image/pc/index_nav1.png"),
          text_1: t("BACK2.0瀏覽器"),
          text_2: t("瀏覽器數據更加便捷"),
          btn_text: t("立即檢視"),
          url: "/#/block"
        },
        {
          src: require("@/assets/image/pc/index_nav2.png"),
          text_1: t("USDQ和PPT快速兌換"),
          text_2: t("幣種兌換方便快捷"),
          btn_text: t("點擊交易"),
          url: "/#/flash/exchange"
        },
        {
          src: require("@/assets/image/pc/index_nav3.png"),
          text_1: t("流動性新增"),
          text_2: t("多幣種選擇，更多體驗"),
          btn_text: t("點擊新增"),
          url: "/swap/#/pool"
        },
        {
          src: require("@/assets/image/pc/index_nav4.png"),
          text_1: t("跨鏈橋"),
          text_2: t("快速輕鬆的實現數字資產交易"),
          btn_text: t("點擊兌換"),
          url: "/#/cross/chain"
        }
      ]
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.index-banner {
  margin: auto;
  height: 330px;
  background: url(~@/assets/image/pc/index_top.png) no-repeat center / 100% 100%;
  .banner-center {
    max-width: 1400px;
    margin: 0 auto;
    overflow: hidden;
    .center-text-big {
      color: #fff;
      font-size: 50px;
      font-weight: bold;
      margin-top: 80px;
    }
    .center-text-small {
      color: #fff;
      margin-top: 10px;
      font-size: 22px;
    }
    .center-text-btn {
      display: block;
      cursor: pointer;
      margin-top: 30px;
      width: 120px;
      height: 34px;
      background: #fff;
      border-radius: 17px;
      text-align: center;
      line-height: 34px;
      color: #0450c6;
      font-size: 16px;
      font-weight: bold;
      transition: 0.5s;
      &:hover {
        background: #d2e2ff;
        // color: #fff;
      }
    }
  }
}
.content {
  max-width: 1400px;
  margin: 0 auto;
  .index-title {
    margin-top: 35px;
    min-height: 76px;
    text-align: center;
    position: relative;
    .title-en {
      color: rgba(62, 63, 74, 0.17);
      font-size: 38px;
      font-weight: 400;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .title-zh {
      color: #111;
      font-size: 32px;
      font-weight: bold;
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    &::after {
      position: absolute;
      content: "";
      width: 50px;
      height: 4px;
      border-radius: 2px;
      background: #0956d0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .content-list {
    max-width: 1400px;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .content-item {
      cursor: pointer;
      width: 300px;
      height: 400px;
      background: #fff;
      border-radius: 20px;
      overflow: hidden;
      text-align: center;
      margin-top: 50px;
      .item-logo {
        display: block;
        width: 180px;
        height: 180px;
        margin: 30px auto 0;
      }
      &:hover .item-logo {
        // width: 200px;
        // height: 200px;
        transition: 0.2s;
        margin: 20px auto 0;
      }
      .item-text-big {
        color: #111;
        font-size: 22px;
        font-weight: bold;
        margin-top: 10px;
      }
      .item-text-small {
        color: #666;
        font-size: 16px;
        margin-top: 10px;
      }
      .item-btn {
        display: block;
        border: 1px solid #0956d0;
        height: 32px;
        line-height: 32px;
        border-radius: 17px;
        width: 130px;
        text-align: center;
        color: #0956d0;
        font-size: 16px;
        margin: 20px auto 0;
        transition: 0.5s;
        &:hover {
          color: #fff !important;
          background: #0956d0;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 480px) {
  .index-banner {
    // width: 100%;
    height: 165px;
    // height: 100%;
    background: url(~@/assets/image/wap/index_top.png) no-repeat center / 100%
      100%;
    .pc-banner {
      display: none;
    }
    .mobile-banner {
      display: block;
    }
    .banner-center {
      margin-left: 16px;
      .center-text-big {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-top: 38px;
      }
      .center-text-small {
        font-size: 11px;
        margin-top: 5px;
        color: #fff;
      }
      .center-text-btn {
        width: 70px;
        height: 25px;
        font-size: 8px;
        font-weight: bold;
        border-radius: 25px;
        line-height: 25px;
        margin-top: 17px;
      }
    }
  }
  .content {
    .index-title {
      margin: 20px 60px 0;
      min-height: 49px;
      .title-en {
        font-size: 19px;
        top: 11px;
        left: 0;
        right: 0;
      }
      .title-zh {
        font-weight: bold;
        color: #111;
        font-size: 18px;
      }
      &::after {
        position: absolute;
        content: "";
        width: 25px;
        height: 2px;
        border-radius: 2px;
        background: #0956d0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    .content-list {
      display: flex;
      justify-content: space-around;
      margin-top: 15px;
      .content-item {
        width: 45%;
        height: 230px;
        cursor: none;
        margin-top: 15px;
        .item-logo {
          width: 102px;
          height: 102px;
          margin: 10px auto 0 !important;
        }
        :hover .item-logo {
          // width: 200px;
          // height: 200px;
          transition: 0;
          margin: 0;
        }
        .item-text-big {
          font-size: 14px;
        }
        .item-text-small {
          color: #999;
          font-size: 11px;
        }
        .item-btn {
          width: 90px;
          height: 30px;
          font-size: 12px;
          line-height: 30px;
          margin: 17px auto 0;
        }
        // &:hover {
        //   background: none;
        //   // color: #fff;
        // }
      }
    }
  }
}
</style>
