<template>
  <div class="page">
    <div class="header">
      <div class="flex-between flex-middle">
        <div class="left">
          <img src="~@/assets/image/wap/mack.png" alt="" />
        </div>
        <div class="right  flex-middle">
          <!-- <div class="item">
            <img class="icon" src="~@/assets/image/wap/sous.png" alt="" />
          </div> -->
          <div class="item">
            <img
              @click.stop="shows"
              class="icon"
              src="~@/assets/image/wap/bianx.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <sidebar v-show="show" @showfun="showfun"></sidebar>
  <!--  -->
</template>

<script>
import { reactive, toRefs } from "vue";
import sidebar from "@/components/Sidebar";
export default {
  components: { sidebar },
  setup() {
    const data = reactive({
      show: false,
      shows: () => {
        data.show = true;
      },
      showfun: () => {
        data.show = false;
      }
    });
    const refDate = toRefs(data);
    return { ...refDate };
  }
};
</script>

<style lang="scss" scoped>
.page {
  background: #fff;
  height: 60px;
  overflow: hidden;
  .header {
    margin: 15px 15px;

    .left {
      width: 71px;
      height: 31.5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      .item {
        .icon {
          width: 18px;
          height: 18px;
          margin: 0 8px;
        }
      }
    }
  }
}
</style>
