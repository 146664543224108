<template>
  <div class="package">
    <div class="rigt">
      <div class="cou flex-middle" @click="hide">
        <img src="~@/assets/image/wap/ca.png" alt="" />
      </div>
      <div class="warp" @click="hide">
        <div
          class="flex-middle item"
          :class="{ active: $route.name === 'Index' }"
          @click="$router.push({ name: 'Index' })"
        >
          <img class="item-icon" src="~@/assets/image/wap/homes.png" alt="" />
          <img class="item-icon" src="~@/assets/image/wap/hone.png" alt="" />
          <div class="title">{{ $t("首頁") }}</div>
        </div>
        <div>
          <div
            class="flex-middle item"
            :class="{
              active: numtype === '2' || $route.path.indexOf('/block') > -1
            }"
            @click="$router.push({ name: 'Block' })"
          >
            <img class="item-icon" src="~@/assets/image/wap/liul.png" alt="" />
            <img class="item-icon" src="~@/assets/image/wap/liu.png" alt="" />
            <div class="title flex-item">{{ $t("區塊鏈") }}</div>
            <div class="right flex-middle" @click.stop="event('2')">
              <img
                class="icon"
                v-if="numtype === '2'"
                src="~@/assets/image/wap/zen.png"
                alt=""
              />
              <img
                class="icon"
                v-else
                src="~@/assets/image/wap/fan.png"
                alt=""
              />
            </div>
          </div>
          <div v-show="numtype === '2'" class="item-title">
            <div class="text" @click="$router.push({ name: 'BlockList' })">
              {{ $t("區塊") }}
            </div>
            <div class="text" @click="$router.push({ name: 'Transaction' })">
              {{ $t("交易") }}
            </div>
            <div class="text" @click="$router.push({ name: 'Token' })">
              {{ $t("代幣") }}
            </div>
          </div>
        </div>
        <div
          class="flex-middle item"
          :class="{ active: $route.name === 'FlashExchange' }"
          @click="$router.push({ name: 'FlashExchange' })"
        >
          <img class="item-icon" src="~@/assets/image/wap/sxa.png" alt="" />
          <img class="item-icon" src="~@/assets/image/wap/sx.png" alt="" />
          <div class="title">{{ $t("閃兌") }}</div>
        </div>
        <div class="flex-middle item" @click="toSwap">
          <img class="item-icon" src="~@/assets/image/wap/qha.png" alt="" />
          <img class="item-icon" src="~@/assets/image/wap/qh.png" alt="" />
          <div class="title">{{ $t("去中心化交易所") }}</div>
        </div>
        <div>
          <div
            class="flex-middle item"
            :class="{ active: numtype === '5' || $route.name === 'MiningLp' }"
            @click="$router.push({ name: 'MiningLp' })"
          >
            <img class="item-icon" src="~@/assets/image/wap/wqa.png" alt="" />
            <img class="item-icon" src="~@/assets/image/wap/wq.png" alt="" />
            <div class="title flex-item">{{ $t("挖礦") }}</div>
            <div class="right flex-middle" @click.stop="event('5')">
              <img
                v-if="numtype === '5'"
                class="icon"
                src="~@/assets/image/wap/zen.png"
                alt=""
              />
              <img
                v-else
                class="icon"
                src="~@/assets/image/wap/fan.png"
                alt=""
              />
            </div>
          </div>
          <div v-show="numtype === '5'" class="item-title">
            <div
              class="text"
              @click="$router.push({ name: 'MiningLp', query: { type: '2' } })"
            >
              {{ $t("單幣挖礦") }}
            </div>
            <div
              class="text"
              @click="$router.push({ name: 'MiningLp', query: { type: '1' } })"
            >
              {{ $t("LP挖礦") }}
            </div>
          </div>
        </div>
        <div
          class="flex-middle item"
          :class="{ active: $route.name === 'CrossChain' }"
          @click="$router.push({ name: 'CrossChain' })"
        >
          <img class="item-icon" src="~@/assets/image/wap/kya.png" alt="" />
          <img class="item-icon" src="~@/assets/image/wap/ky.png" alt="" />
          <div class="title">{{ $t("跨鏈橋") }}</div>
        </div>
        <div
          class="flex-middle item"
          :class="{ active: $route.name === 'PlaceMent' }"
          @click="$router.push({ name: 'PlaceMent' })"
        >
          <img class="item-icon" src="~@/assets/image/wap/idoa.png" alt="" />
          <img class="item-icon" src="~@/assets/image/wap/ido.png" alt="" />
          <div class="title">IDO</div>
        </div>
        <div>
          <div
            class="flex-middle item"
            :class="{ active: numtype === '8' }"
            @click.stop=""
          >
            <img class="item-icon" src="~@/assets/image/wap/qqa.png" alt="" />
            <img class="item-icon" src="~@/assets/image/wap/qq.png" alt="" />
            <div class="title flex-item">
              {{ local === "en" ? "English" : "繁體中文" }}
            </div>
            <div class="right flex-middle" @click.stop="event('8')">
              <img
                class="icon"
                v-if="numtype === '8'"
                src="~@/assets/image/wap/zen.png"
                alt=""
              />
              <img
                class="icon"
                v-else
                src="~@/assets/image/wap/fan.png"
                alt=""
              />
            </div>
          </div>
          <div v-show="numtype === '8'" class="item-title">
            <div class="text" @click="changeLanguage('zh-tw')">繁體中文</div>
            <div class="text" @click="changeLanguage('en')">English</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch } from "vue";
import { setLang } from "@/utils/i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      numtype: "",
      local: computed(() => store.state.locale),
      event: type => {
        if (data.numtype === type) {
          return (data.numtype = "");
        }
        data.numtype = type;
      },
      changeLanguage: inject => {
        setLang(inject);
        router.go(0);
      },
      toSwap: () => {
        window.location.href = "/swap";
      },
      hide: () => {
        context.emit("showfun");
      }
    });
    const refDate = toRefs(data);
    return { ...refDate };
  }
};
</script>

<style lang="scss" scoped>
.package {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.6);
  z-index: 1111;
  .rigt {
    position: absolute;
    width: 56%;
    height: 100%;
    right: 0;
    background: #fff;
    padding: 57px 0 20px;
    overflow: auto;
    .cou {
      position: absolute;
      height: 57px;
      padding: 0 17px;
      position: absolute;
      right: 0;
      top: 0;
      img {
        width: 13px;
        height: 13px;
      }
    }
    .warp {
      .item {
        height: 48px;
        padding: 0 0 0 18px;
        .item-icon {
          width: 19px;
          height: 19px;
          &:nth-child(1) {
            display: none;
          }
        }
        .title {
          font-size: 15px;
          color: #111;
          font-weight: bold;
          margin-left: 14.5px;
        }
      }
      .active {
        background: #ebebeb;
        .item-icon {
          &:nth-child(1) {
            display: block;
          }
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
.right {
  height: 100%;
  padding: 0 20px;
}
.icon {
  width: 7.5px;
  height: 4.5px;
}
.item-title {
  .text {
    font-size: 14px;
    clear: #111;
    font-weight: bold;
    line-height: 48px;
    padding: 0 0 0 52px;
  }
}
</style>
