import { config } from './config'
// const poolList = [
//      {
//         pid: 0,
//         lpAddresses: "0x5a1a2355068f4451bEb244362A773Ba6deBC3638",
//         tokenAddress: "0x5a1a2355068f4451bEb244362A773Ba6deBC3638",
//         tokenA: config.kisAddress,
//         tokenB: config.woktAddress,
//         name: "KIS LP",
//         symbol: "KIS/OKT",
//         tokenSymbol: "KIS/OKT",
//         earnPercent: "1230%",
//         allocPoint: 267,
//         usdtTotal: 0,
//         rate: 0,
//         iconA: "",
//         iconB: "",
//         decimal: 18,
//         islp: true
//      },
//      {
//       pid: 1,
//       lpAddresses: "0x3769bb80a8FcF6A4e76239F189725928B9De4E7d",
//       tokenAddress: "0x3769bb80a8FcF6A4e76239F189725928B9De4E7d",
//       tokenA: config.kisAddress,
//       tokenB: config.usdtAddress,
//       name: "KIS LP",
//       symbol: "KIS/USDT",
//       tokenSymbol: "KIS/USDT",
//       earnPercent: "1670%",
//       allocPoint: 233,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: true
//    },
//    {
//       pid: 2,
//       lpAddresses: "0x88db8314a60AA34567a2015996B9E2AEEC18B06E",
//       tokenAddress: "0x88db8314a60AA34567a2015996B9E2AEEC18B06E",
//       tokenA: config.btckAddress,
//       tokenB: config.usdtAddress,
//       name: "KIS LP",
//       symbol: "BTCK/USDT",
//       tokenSymbol: "BTCK/USDT",
//       earnPercent: "1670%",
//       allocPoint: 33,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: true
//    },
//    {
//       pid: 3,
//       lpAddresses: "0x8874626dB30F2dA3D5fE1064546f8359278e4F51",
//       tokenAddress: "0x8874626dB30F2dA3D5fE1064546f8359278e4F51",
//       tokenA: config.ethkAddress,
//       tokenB: config.usdtAddress,
//       name: "KIS LP",
//       symbol: "ETHK/USDT",
//       tokenSymbol: "ETHK/USDT",
//       earnPercent: "1670%",
//       allocPoint: 33,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: true
//    },
//    {
//       pid: 4,
//       lpAddresses: "0x4834c47227bA17afF90342405711a67ed6b8FD6b",
//       tokenAddress: "0x4834c47227bA17afF90342405711a67ed6b8FD6b",
//       tokenA: config.dotkAddress,
//       tokenB: config.usdtAddress,
//       name: "KIS LP",
//       symbol: "DOTK/USDT",
//       tokenSymbol: "DOTK/USDT",
//       earnPercent: "1670%",
//       allocPoint: 33,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: true
//    },
//    {
//       pid: 5,
//       lpAddresses: "0x69E2329010987D880Ff4F79B4d8Bb1D6f7194B55",
//       tokenAddress: "0x69E2329010987D880Ff4F79B4d8Bb1D6f7194B55",
//       tokenA: config.filkAddress,
//       tokenB: config.usdtAddress,
//       name: "KIS LP",
//       symbol: "FILK/USDT",
//       tokenSymbol: "FILK/USDT",
//       earnPercent: "1670%",
//       allocPoint: 66,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: true
//    },
//    {
//       pid: 6,
//       lpAddresses: "0xADb5bDC60F0B9ac7C8c345a2a8055535Fe641d85",
//       tokenAddress: "0xADb5bDC60F0B9ac7C8c345a2a8055535Fe641d85",
//       tokenA: config.okbAddress,
//       tokenB: config.usdtAddress,
//       name: "KIS LP",
//       symbol: "OKB/USDT",
//       tokenSymbol: "OKB/USDT",
//       earnPercent: "1670%",
//       allocPoint: 66,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: true
//    },
//    {
//       pid: 7,
//       lpAddresses: "0x85e30ee84cB1CCa55A270cE925b1a6303d75B3D9",
//       tokenAddress: "0x85e30ee84cB1CCa55A270cE925b1a6303d75B3D9",
//       tokenA: config.woktAddress,
//       tokenB: config.usdtAddress,
//       name: "KIS LP",
//       symbol: "OKT/USDT",
//       tokenSymbol: "OKT/USDT",
//       earnPercent: "1670%",
//       allocPoint: 66,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: true
//    },
//    {
//       pid: 8,
//       lpAddresses: config.woktAddress,
//       tokenAddress: config.woktAddress,
//       tokenA: config.woktAddress,
//       tokenB: config.woktAddress,
//       name: "WOKT",
//       symbol: "WOKT",
//       tokenSymbol: "WOKT",
//       earnPercent: "1670%",
//       allocPoint: 16,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    },
//    {
//       pid: 9,
//       lpAddresses: config.kisAddress,
//       tokenAddress: config.kisAddress,
//       tokenA: config.kisAddress,
//       tokenB: config.kisAddress,
//       name: "KIS",
//       symbol: "KIS",
//       tokenSymbol: "KIS",
//       earnPercent: "1670%",
//       allocPoint: 66,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    },
//    {
//       pid: 10,
//       lpAddresses: config.keyAddress,
//       tokenAddress: config.keyAddress,
//       tokenA: config.keyAddress,
//       tokenB: config.keyAddress,
//       name: "KEY",
//       symbol: "KEY",
//       tokenSymbol: "KEY",
//       earnPercent: "1670%",
//       allocPoint: 3,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    },
//    {
//       pid: 11,
//       lpAddresses: config.usdtAddress,
//       tokenAddress: config.usdtAddress,
//       tokenA: config.usdtAddress,
//       tokenB: config.usdtAddress,
//       name: "USDT",
//       symbol: "USDT",
//       tokenSymbol: "USDT",
//       earnPercent: "1670%",
//       allocPoint: 13,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    },
//    {
//       pid: 12,
//       lpAddresses: config.ethkAddress,
//       tokenAddress: config.ethkAddress,
//       tokenA: config.ethkAddress,
//       tokenB: config.ethkAddress,
//       name: "ETHK",
//       symbol: "ETHK",
//       tokenSymbol: "ETHK",
//       earnPercent: "1670%",
//       allocPoint: 8,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    },
//    {
//       pid: 13,
//       lpAddresses: config.btckAddress,
//       tokenAddress: config.btckAddress,
//       tokenA: config.btckAddress,
//       tokenB: config.btckAddress,
//       name: "BTCK",
//       symbol: "BTCK",
//       tokenSymbol: "BTCK",
//       earnPercent: "1670%",
//       allocPoint: 8,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    },
//    {
//       pid: 14,
//       lpAddresses: config.dotkAddress,
//       tokenAddress: config.dotkAddress,
//       tokenA: config.dotkAddress,
//       tokenB: config.dotkAddress,
//       name: "DOTK",
//       symbol: "DOTK",
//       tokenSymbol: "DOTK",
//       earnPercent: "1670%",
//       allocPoint: 8,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    },
//    {
//       pid: 15,
//       lpAddresses: config.filkAddress,
//       tokenAddress: config.filkAddress,
//       tokenA: config.filkAddress,
//       tokenB: config.filkAddress,
//       name: "FILK",
//       symbol: "FILK",
//       tokenSymbol: "FILK",
//       earnPercent: "1670%",
//       allocPoint: 75,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    }
// ]
// const poolList = [
//    {
//       pid: 0,
//       lpAddresses: "0xD3c51c452e06e2975d4973771b58890c68a5E72E",
//       tokenAddress: "0xD3c51c452e06e2975d4973771b58890c68a5E72E",
//       tokenA: config.kisAddress,
//       tokenB: config.usdtAddress,
//       name: "KIS LP",
//       symbol: "KIS/USDT",
//       tokenSymbol: "KIS/USDT",
//       earnPercent: "1670%",
//       allocPoint: 616,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: true
//    },
//    {
//       pid: 1,
//       lpAddresses: config.filkAddress,
//       tokenAddress: config.filkAddress,
//       tokenA: config.filkAddress,
//       tokenB: config.filkAddress,
//       name: "FILK",
//       symbol: "FILK",
//       tokenSymbol: "FILK",
//       earnPercent: "1670%",
//       allocPoint: 198,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    },
//    {
//       pid: 2,
//       lpAddresses: config.kisAddress,
//       tokenAddress: config.kisAddress,
//       tokenA: config.kisAddress,
//       tokenB: config.kisAddress,
//       name: "KIS",
//       symbol: "KIS",
//       tokenSymbol: "KIS",
//       earnPercent: "1670%",
//       allocPoint: 176,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    },
//    {
//       pid: 3,
//       lpAddresses: config.keyAddress,
//       tokenAddress: config.keyAddress,
//       tokenA: config.keyAddress,
//       tokenB: config.keyAddress,
//       name: "KEY",
//       symbol: "KEY",
//       tokenSymbol: "KEY",
//       earnPercent: "1670%",
//       allocPoint: 8,
//       usdtTotal: 0,
//       rate: 0,
//       iconA: "",
//       iconB: "",
//       decimal: 18,
//       islp: false
//    }
// ]
const poolList = [
   {
      pid: 0,
      lpAddresses: "0xD2cF1bA50874C0BfC03b234f9Bf7287863a9bABc",
      tokenAddress: "0xD2cF1bA50874C0BfC03b234f9Bf7287863a9bABc",
      tokenA: config.kisAddress,
      tokenB: config.sscAddress,
      name: "KIS LP",
      symbol: "KIS/SSC",
      tokenSymbol: "KIS/SSC",
      earnPercent: "1670%",
      allocPoint: 714,
      usdtTotal: 0,
      rate: 0,
      iconA: "",
      iconB: "",
      decimal: 18,
      islp: true
   },
   {
      pid: 1,
      lpAddresses: config.sscAddress,
      tokenAddress: config.sscAddress,
      tokenA: config.sscAddress,
      tokenB: config.sscAddress,
      name: "SSC",
      symbol: "SSC",
      tokenSymbol: "SSC",
      earnPercent: "1670%",
      allocPoint: 214,
      usdtTotal: 0,
      rate: 0,
      iconA: "",
      iconB: "",
      decimal: 18,
      islp: false
   },
   {
      pid: 2,
      lpAddresses: config.kisAddress,
      tokenAddress: config.kisAddress,
      tokenA: config.kisAddress,
      tokenB: config.kisAddress,
      name: "KIS",
      symbol: "KIS",
      tokenSymbol: "KIS",
      earnPercent: "1670%",
      allocPoint: 48,
      usdtTotal: 0,
      rate: 0,
      iconA: "",
      iconB: "",
      decimal: 18,
      islp: false
   },
   {
      pid: 3,
      lpAddresses: config.keyAddress,
      tokenAddress: config.keyAddress,
      tokenA: config.keyAddress,
      tokenB: config.keyAddress,
      name: "KEY",
      symbol: "KEY",
      tokenSymbol: "KEY",
      earnPercent: "1670%",
      allocPoint: 24,
      usdtTotal: 0,
      rate: 0,
      iconA: "",
      iconB: "",
      decimal: 18,
      islp: false
   }
]
export default poolList;
