<template>
  <div class="page-header">
    <div class="page-header-wrapper">
      <div class="container-wrapper flex-middle flex-between">
        <div class="left flex-middle">
          <div class="site">
            <div class="img-wrap flex-middle">
              <img
                :src="require('@/assets/logo.png')"
                class="logo"
                @error="$setErrorImg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="right">
          <div class="menu-wrap flex-middle">
            <div class="menu-box flex-middle" @mouseleave="menuleave">
              <div
                class="menu-item"
                @mouseover="menuover"
                :class="{ 'menu-item--active': $route.name === 'Index' }"
                @click="$router.push({ name: 'Index' })"
              >
                {{ $t("首頁") }}
              </div>
              <div class="sub-menu">
                <div
                  class="menu-item"
                  :class="{
                    'menu-item--active': $route.path.indexOf('/block') > -1
                  }"
                  @mouseover="menuover"
                  @click="$router.push({ name: 'Block' })"
                >
                  {{ $t("區塊鏈") }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                  <i class="el-icon-arrow-up el-icon--right"></i>
                </div>
                <div class="sub-menu--box">
                  <div
                    class="menu-item"
                    :class="{
                      'menu-item--active': $route.name === 'BlockList'
                    }"
                    @click="$router.push({ name: 'BlockList' })"
                  >
                    {{ $t("區塊") }}
                  </div>
                  <div
                    class="menu-item"
                    :class="{
                      'menu-item--active': $route.name === 'Transaction'
                    }"
                    @click="$router.push({ name: 'Transaction' })"
                  >
                    {{ $t("交易") }}
                  </div>
                  <div
                    class="menu-item"
                    :class="{
                      'menu-item--active': $route.name === 'Token'
                    }"
                    @click="$router.push({ name: 'Token' })"
                  >
                    {{ $t("代幣") }}
                  </div>
                </div>
              </div>
              <div
                class="menu-item"
                @mouseover="menuover"
                :class="{
                  'menu-item--active': $route.name === 'FlashExchange'
                }"
                @click="$router.push({ name: 'FlashExchange' })"
              >
                {{ $t("閃兌") }}
              </div>
              <div class="menu-item" @mouseover="menuover" @click="toSwap">
                {{ $t("去中心化交易所") }}
              </div>
              <div class="sub-menu">
                <div
                  class="menu-item"
                  @mouseover="menuover"
                  :class="{ 'menu-item--active': $route.name === 'MiningLp' }"
                  @click="$router.push({ name: 'MiningLp' })"
                >
                  {{ $t("挖礦") }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                  <i class="el-icon-arrow-up el-icon--right"></i>
                </div>
                <div class="sub-menu--box">
                  <div
                    class="menu-item"
                    :class="{
                      'menu-item--active':
                        $route.name === 'MiningLp' &&
                        $route.query &&
                        $route.query.type === '2'
                    }"
                    @click="
                      $router.push({ name: 'MiningLp', query: { type: '2' } })
                    "
                  >
                    {{ $t("單幣挖礦") }}
                  </div>
                  <div
                    class="menu-item"
                    :class="{
                      'menu-item--active':
                        $route.name === 'MiningLp' &&
                        $route.query &&
                        $route.query.type === '1'
                    }"
                    @click="
                      $router.push({ name: 'MiningLp', query: { type: '1' } })
                    "
                  >
                    {{ $t("LP挖礦") }}
                  </div>
                </div>
              </div>
              <div
                class="menu-item"
                @mouseover="menuover"
                :class="{ 'menu-item--active': $route.name === 'CrossChain' }"
                @click="$router.push({ name: 'CrossChain' })"
              >
                {{ $t("跨鏈橋") }}
              </div>
              <div
                class="menu-item"
                @mouseover="menuover"
                :class="{ 'menu-item--active': $route.name === 'PlaceMent' }"
                @click="$router.push({ name: 'PlaceMent' })"
              >
                IDO
              </div>
              <div class="sub-menu">
                <div class="menu-item" @mouseover="menuover">
                  {{ locale === "en" ? "English" : "繁體中文" }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                  <i class="el-icon-arrow-up el-icon--right"></i>
                </div>
                <div class="sub-menu--box">
                  <div
                    class="menu-item"
                    :class="{
                      'menu-item--active': locale === 'en'
                    }"
                    @click="changeLanguage('en')"
                  >
                    English
                  </div>
                  <div
                    class="menu-item"
                    :class="{
                      'menu-item--active': locale === 'zh-tw'
                    }"
                    @click="changeLanguage('zh-tw')"
                  >
                    繁體中文
                  </div>
                </div>
              </div>
              <div class="sub-menu">
                <div class="sub-menu--box">
                  <div
                    v-if="locale === 'zh-tw'"
                    class="menu-item"
                    @click="changeLanguage('en')"
                  >
                    English
                  </div>
                  <div v-if="locale === 'en'" class="menu-item">
                    簡體中文
                  </div>
                </div>
              </div>
            </div>
            <div class="menu-line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import { setLang } from "@/utils/i18n";
import { useStore } from "vuex";
import { themeMode } from "@/storage";
export default {
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      themeMode: computed(() => store.state.themeMode),
      mobileShowMenu: false,
      locale: computed(() => store.state.locale),
      tokenlist: [],
      toSwap: () => {
        window.location.href = "/swap";
      },
      handleSelect: () => {
        data.mobileShowMenu = false;
      },
      changeThemeMode: () => {
        let theme = data.themeMode === "dark" ? "light" : "dark";
        themeMode.set(theme);
        store.commit("updateThemeMode", theme);
      },
      changeLanguage: inject => {
        setLang(inject);
        router.go(0);
      },
      menuover: e => {
        let mLeft = 0;
        let mWidth = 0;
        if (e.target.offsetParent.className.includes("sub-menu")) {
          mLeft = e.target.offsetParent.offsetLeft;
          mWidth = e.target.offsetParent.offsetWidth;
        } else {
          mLeft = e.target.offsetLeft;
          mWidth = e.target.offsetWidth;
        }
        document.getElementsByClassName("menu-line")[0].style.width =
          mWidth + "px";
        document.getElementsByClassName("menu-line")[0].style.opacity = 1;
        document.getElementsByClassName("menu-line")[0].style.left =
          mLeft + "px";
      },
      menuleave: () => {
        document.getElementsByClassName("menu-line")[0].style.opacity = 0;
      }
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.page-header {
  background: #ffffff;
  height: 60px;
  .page-header-wrapper {
    position: fixed;
    z-index: 300;
    width: 100%;
    padding: 0 30px;
    margin: 0 auto;
    box-sizing: border-box;
    background: #ffffff;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
  }
  .logo {
    width: 94px;
    height: auto;
    display: block;
    margin-right: 12px;
  }
  .site {
    padding-right: 15px;
  }
  .menu-wrap {
    position: relative;
  }
  .menu-line {
    opacity: 0;
    position: absolute;
    top: 56px;
    height: 3px;
    background: #0956d0;
    transition: all 0.3s;
    pointer-events: none;
    &::before {
      content: "";
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 10px;
      border-bottom: 4px solid #0956d0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
  .menu-item {
    padding: 0 30px;
    font-size: 16px;
    color: #000;
    height: 60px;
    line-height: 60px;
    white-space: nowrap;
    .el-icon-arrow-down {
      display: inline-block;
    }
    .el-icon-arrow-up {
      display: none;
    }
    cursor: pointer;
    &.menu-item--active {
      color: #0956d0;
      .el-icon-arrow-down {
        display: none;
      }
      .el-icon-arrow-up {
        display: inline-block;
      }
    }
    &:hover {
      color: #0956d0;
      .el-icon-arrow-down {
        display: none;
      }
      .el-icon-arrow-up {
        display: inline-block;
      }
    }
    i {
      font-size: 12px;
      pointer-events: none;
    }
  }
  .sub-menu {
    position: relative;
    .sub-menu--box {
      display: none;
      min-width: 100%;
      position: absolute;
      top: 56px;
      left: 50%;
      transform: translateX(-50%);
      background: #fff;
      border-top: 3px solid #0956d0;
      max-height: 300px;
      overflow: auto;
      &::before {
        content: "";
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 10px;
        border-bottom: 4px solid #0956d0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }
      .menu-item {
        padding: 0 30px;
        text-align: center;
        white-space: nowrap;
        &:hover {
          background: rgba(9, 86, 208, 0.2);
        }
      }
      .menu-item + .menu-item {
        // border-top: 1px solid #0956d0;
      }
    }
    &:hover {
      & > .menu-item {
        color: #0956d0;
      }
      .sub-menu--box {
        display: block;
      }
      .el-icon-arrow-down {
        display: none;
      }
      .el-icon-arrow-up {
        display: inline-block;
      }
    }
  }
}
</style>
<style lang="scss">
.page-header {
  .menu-wrap {
    .ant-menu-horizontal {
      border: 0;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      padding: 8px 30px;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 42px;
    }
  }
}
</style>
