const locale = extend('i18nextLng')
// 主题色
const themeMode = extend('themeMode', sessionStorage)
function setData (key, value, storage = localStorage) {
    try {
      storage.setItem(key, value)
    } catch (e) {
   }
}
function extend (key, storage = localStorage) {
    return {
      get () {
        return storage.getItem(key)
      },
      set (value) {
        setData(key, value, storage)
      },
      remove () {
        storage.removeItem(key)
      }
    }
  }

export { locale, themeMode }
