<template>
  <div class="index-top">
    <div class="t-text">
      <h1>{{h1}}</h1>
    </div>
    <h4>{{h4}}</h4>
  </div>
</template>
<script>
export default {
  props: ['h1', 'h4']
}
</script>
<style lang="scss" scoped>
.index-top {
  min-width: 1200px;
  margin: 28px 0 0 0;
  color: #fff;
  text-align: center;
  position: relative;
  .t-text {
    padding-bottom: 50px;
    background: url('~@/assets/image/topline.png') no-repeat center bottom;;
    background-size: auto 100%;
  }
  h1,
  h4 {
    max-width: 1000px;
    line-height: 1.2;
    margin: auto;
  }
  h1 {
    font-size: 34px;
    font-weight: 700;
    min-height: 40px;
  }
  h4 {
    font-size: 24px;
    font-weight: normal;
  }
}
.theme-light {
  .index-top {
    color: #333;
    .t-text {
      background-image: url('~@/assets/image/light/topline.png');
    }
  }
}
@media screen and (max-width: 480px) {
  .index-top {
    min-width: 100%;
    margin: 14px 0 0;
    .t-text {
      background-size: auto 26px;
      padding-bottom: 20px;
    }
    h1,
    h4 {
      line-height: 1.4;
      max-width: 85%;
    }
    h1 {
      font-size: 18px;
      bottom: 12px;
      min-height: 25px;
    }
    h4 {
      font-size: 14px;
      top: 25px;
    }
  }
}
</style>
