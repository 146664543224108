import { createI18n } from "vue-i18n";
import { locale } from "../storage";
import store from "../store";
import enLocale from "element-plus/lib/locale/lang/en";
import zhLocale from "element-plus/lib/locale/lang/zh-tw";
import zh_tw from "../locales/zh-tw.json";
import en from "../locales/en.json";
let jsonObj = zh_tw;
export const i18n = createI18n({
  locale: "en",
  messages: {
    "zh-tw": {
      ...zh_tw,
      ...zhLocale
    },
    en: {
      ...en,
      ...enLocale
    }
  },
  missing: (locale, key) => {
    let flag = key.indexOf(".");
    if (flag !== -1) {
      let strArr = key.split(".");
      console.log(strArr);
      jsonObj[strArr[0]][strArr[1]] = strArr[1];
    } else {
      jsonObj[key] = key;
    }
    // console.log(JSON.stringify(jsonObj));
  }
});

export const setLang = lang => {
  i18n.global.locale = lang;
  store.commit("updateLocale", lang);
  locale.set(lang);
};

export const getLang = () => {
  return i18n && i18n.global.locale;
};
