import { createRouter, createWebHashHistory } from "vue-router";
import Index from "../views/index";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index
  },
  {
    path: "/address/list",
    name: "AddressList",
    component: () => import("../views/addressList")
  },
  {
    path: "/cross/chain",
    name: "CrossChain",
    component: () => import("../views/crossChain")
  },
  {
    path: "/pledge/:type/:name/:token",
    name: "Pledge",
    component: () => import("../views/pledge")
  },
  {
    path: "/withdraw/:type/:name/:token",
    name: "Withdraw",
    component: () => import("../views/withdraw")
  },
  {
    path: "/ido",
    name: "Ido",
    component: () => import("../views/ido")
  },
  {
    path: "/flash/exchange",
    name: "FlashExchange",
    component: () => import("../views/flashExchange")
  },
  {
    path: "/mining/lp",
    name: "MiningLp",
    component: () => import("../views/mining/lp")
  },
  {
    path: "/block",
    name: "Block",
    component: () => import("../views/block")
  },
  {
    path: "/block/address/detail/:address",
    name: "AddressDetail",
    component: () =>
      import(
        /* webpackChunkName: "AddressDetail" */ "../views/block/address/detail"
      )
  },
  {
    path: "/block/block",
    name: "BlockList",
    component: () =>
      import(/* webpackChunkName: "Transaction" */ "../views/block/block")
  },
  {
    path: "/block/block/detail/:blockNumber",
    name: "BlockDetial",
    component: () =>
      import(
        /* webpackChunkName: "BlockDetial" */ "../views/block/block/detail"
      )
  },
  {
    path: "/block/transaction",
    name: "Transaction",
    component: () =>
      import(/* webpackChunkName: "Transaction" */ "../views/block/transaction")
  },
  {
    path: "/block/transaction/detial/:hash",
    name: "TransactionDetial",
    component: () =>
      import(
        /* webpackChunkName: "TransactionDetial" */ "../views/block/transaction/detail"
      )
  },
  {
    path: "/block/token",
    name: "Token",
    component: () =>
      import(/* webpackChunkName: "Transaction" */ "../views/block/token")
  },
  {
    path: "/placement",
    name: "PlaceMent",
    component: () =>
      import(/* webpackChunkName: "Transaction" */ "../views/placement")
  },
  {
    path: "/placement/receive",
    name: "PlaceMentReceive",
    component: () =>
      import(/* webpackChunkName: "Transaction" */ "../views/placement/receive")
  },
  {
    path: "/placement/buy",
    name: "PlaceMentBuy",
    component: () =>
      import(/* webpackChunkName: "Transaction" */ "../views/placement/buy")
  },
  {
    path: "/placement/distribution",
    name: "PlaceMentDistribution",
    component: () =>
      import(/* webpackChunkName: "Transaction" */ "../views/placement/distribution")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
